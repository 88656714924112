import { useMsal } from '@azure/msal-react';

export function GetUserIdAsync() {
  const { accounts } = useMsal()

const userID = accounts[0].localAccountId

  return userID;
}

export function GetUserAccount() {
  const { accounts } = useMsal()

  return  accounts[0];
}
