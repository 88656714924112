import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { type PropsWithChildren, createContext, useMemo } from 'react'
import { appInsightsPlugin, trackException } from './app-insights.instance'
import { type AppInsightsContextType } from './app-insights.types'

const AppInsightsContext = createContext<AppInsightsContextType | undefined>(undefined)

export const AppInsightsProvider = ({ children }: PropsWithChildren) => {
  const ctx = useMemo(() => {
    return {
      trackException,
    } as const
  }, [])

  return (
    <AppInsightsContext.Provider value={ctx}>
      <AppInsightsErrorBoundary onError={() => <></>} appInsights={appInsightsPlugin}>
        <>{children}</>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  )
}
