import { Box, Drawer } from '@mui/material'
import { Outlet } from 'react-router-dom'
import App from '../../App'
import Navbar from '../../components/navbar/Navbar'
import { Topbar } from '../../components/topbar'
import { useAtom } from 'jotai'
import { navigationDrawerAtom } from '../../store/atoms/navigation-drawer'

export const MainLayout = () => {
  const [open, setOpen] = useAtom(navigationDrawerAtom)

  const paddingLeft = open ? '240px' : '0'

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        transition: 'backdrop-filter 0.3s ease',
        width: open ? '100%' : 'auto',
        '@media (max-width: 768px)': {
          width: open ? 'calc(100% + 240px)' : '100%',
        },
        '@media (max-width: 960px)': {
          width: open ? 'calc(100% + 240px)' : '100%',
        },
      }}
    >
      <Topbar />
      <Box
        sx={{
          pl: paddingLeft,
        }}
        height={'100vh'}
      >
        <Drawer
          variant="persistent"
          sx={{
            '& .MuiDrawer-paper': { border: 'none' },
          }}
          onClose={() => setOpen((state) => !state)}
          open={open}
          anchor="left"
        >
          <Navbar />
        </Drawer>
        <App />
        <Outlet />
      </Box>
    </Box>
  )
}
