import dayjs from 'dayjs'

export function SetTime(
  dateTime: dayjs.Dayjs,
  hours: number,
  minutes: number,
  seconds: number,
  milliseconds: number,
): dayjs.Dayjs {
  dateTime = dateTime.set('hour', hours)
  dateTime = dateTime.set('minute', minutes)
  dateTime = dateTime.set('second', seconds)
  dateTime = dateTime.set('millisecond', milliseconds)
  return dateTime
}

export function SetCurrentTime(time: dayjs.Dayjs) {
  let currentTime = dayjs()
  return SetTime(time, currentTime.hour(), currentTime.minute(), currentTime.second(), currentTime.millisecond())
}

export function SetStartOfDayTime(time: dayjs.Dayjs) {
  return time.startOf('day')
}

export function SetEndOfDayTime(time: dayjs.Dayjs) {
  return time.endOf('day')
}
