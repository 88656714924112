import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Await, useMatches } from "react-router-dom";
import { hasRouteHandle } from "../../utils/route-handle.helper";
import { isFunction } from "../../utils/is-function.helper";

type MetaObject = { title: string } | { name: string; content: string };

export const Meta = ({ titleTemplate }: { titleTemplate?: string }) => {
  const matches = useMatches();
  const helmet = matches
    .filter(hasRouteHandle<(...args: unknown[]) => MetaObject[]>("meta", isFunction))
    .map((match) => {
      return (
        <Fragment key={match.id}>
          <Await resolve={match.data}>
            {(data) => {
              const meta = match.handle.meta(data);
              return (
                <Helmet titleTemplate={titleTemplate}>
                  {meta.map((item) => {
                    if ("title" in item) {
                      return <title key={item.title}>{item.title}</title>;
                    }
                    if ("name" in item) {
                      return (
                        <meta
                          key={`${item.name}:${item.content}`}
                          name={item.name}
                          content={item.content}
                        />
                      );
                    }

                    return <Fragment key={item} />;
                  })}
                </Helmet>
              );
            }}
          </Await>
        </Fragment>
      );
    });

  return <>{helmet}</>;
};
