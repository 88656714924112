import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH } from './data/constants/paths'
import { AppInsightsProvider } from './app-insights'

function App() {
  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate(PATH.SITE_OVERVIEW)
    }
  }, [navigate])

  return (
    <AppInsightsProvider>
      <div className="App">{}</div>
    </AppInsightsProvider>
  )
}

export default App
