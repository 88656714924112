import { AppBar, Stack, Toolbar, Typography, Box } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/rio_logo.svg'
import { pageTitle } from '../../data/constants/page-titles'
import { hasRouteHandle } from '../../utils/route-handle.helper'
import { Await, useMatches } from 'react-router-dom'
import { IsFunction, isFunction } from '../../utils/is-function.helper'
import { Fragment } from 'react'
import { NavigationDrawerControl } from './nav-drawer-control/nav-drawer-control.component'
import theme from '../../theme'
import { h5 } from '../../data/constants/component-variants'

export function Topbar({ fallbackTitle = pageTitle.dewateringDefault }: { fallbackTitle?: string }) {
  const matches = useMatches()
  const header =
    matches
      .filter(hasRouteHandle<IsFunction<(...args: unknown[]) => string>>('header', isFunction))
      .map((match) => {
        return (
          <Fragment key={match.id}>
            <Await resolve={match.data}>
              {(data) => {
                return match.handle.header(data)
              }}
            </Await>
          </Fragment>
        )
      })
      .at(-1) ?? fallbackTitle

  return (
    <Box className="topbar">
      <AppBar
        sx={{
          backgroundColor: theme.palette.topbar?.main,
          borderBottom: '1px solid',
          borderColor: theme.palette.lightGray?.dark,
          boxShadow: 0,
          zIndex: 9999,
        }}
        data-testid="topbar"
      >
        <Toolbar
          sx={{
            height: '64px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row" alignItems="center" gap="1rem">
            <NavigationDrawerControl />
            <Box display="flex" alignItems="center">
              <Logo width="41px" height="41px" />
            </Box>
            <Typography
              variant={h5}
              fontWeight={700}
              lineHeight={1}
              textTransform={'none'}
              color={theme.palette.black?.main}
            >
              {header}
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
