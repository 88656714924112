import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import theme from './theme'
import { store } from './data/datastore'
import { Provider } from 'jotai'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from './services/query-client'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { routes } from './routes/routes.conf'
import { HelmetProvider } from 'react-helmet-async'
import { muiLicenseKey } from './data/constants/mui-license-key'
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'
import { msalInstance } from './services/msal'
import { InteractionType } from '@azure/msal-browser'
LicenseInfo.setLicenseKey(muiLicenseKey)


const container = document.getElementById('root')
document.body.style.backgroundColor = theme.palette.background?.default

var msalScopes = ['User.Read', 'openid', 'profile']

if (container) {
  const root = ReactDOM.createRoot(container)
  const router = createBrowserRouter(routes)

  root.render(
    <div style={{ width: '100%' }}>
      <StrictMode>
        <HelmetProvider>
          <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={{ scopes: msalScopes }}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools />
              <CssBaseline />
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Provider store={store}>
                    <RouterProvider router={router} fallbackElement={<></>} future={{ v7_startTransition: true }} />
                  </Provider>
                </LocalizationProvider>
              </ThemeProvider>
            </QueryClientProvider>
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </HelmetProvider>
      </StrictMode>
    </div>
  )
}