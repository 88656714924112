import { Outlet, json, type RouteObject, redirect } from 'react-router-dom'
import { MainLayout } from '../layouts/main'
import { PATH } from '../data/constants/paths'
import { Core } from './core.route'
import { pageTitle } from '../data/constants/page-titles'
import { Meta } from '../components/meta'
import { Site } from '../services/api/api.types'
import { getSitesListQuery } from '../services/api/dewatering'
import { queryClient } from '../services/query-client'

export const routes: RouteObject[] = [
  {
    element: <Core />,
    children: [
      {
        path: '/auth/callback',
        element: <></>,
      },
      {
        element: <MainLayout />,
        children: [
          {
            index: true,
            async lazy() {
              const mod = await import('./sites')

              return {
                element: <mod.SitesRoute />,
                handle: {
                  header: () => pageTitle.dewateringDefault,
                  meta: () => [{ title: pageTitle.siteOverview }],
                },
              }
            },
          },

          {
            handle: {
              meta: () => [{ title: pageTitle.dewateringDefault }],
              header: () => pageTitle.dewateringDefault,
            },
            element: (
              <>
                <Meta titleTemplate={`%s | ${pageTitle.dewateringDefault}`} />
                <Outlet />
              </>
            ),
            children: [
              {
                path: 'site/:siteCode',
                handle: {
                  meta: (args?: Partial<{ site: Site }>) => [{ title: args?.site?.name ?? '...' }],
                },
                async loader({ params }) {
                  const sites = await queryClient.ensureQueryData(getSitesListQuery())
                  const site = sites.data.find((site) => site.code === params.siteCode)

                  if (site === undefined) {
                    throw redirect(PATH.HOME)
                  }

                  return json({ site })
                },
                async lazy() {
                  const mod = await import('./site')

                  return {
                    element: <mod.Route />,
                  }
                },
              },
              {
                path: PATH.ABOUT,
                handle: {
                  meta: () => [{ title: pageTitle.about }],
                },
                async lazy() {
                  const mod = await import('./about')

                  return {
                    element: <mod.AboutRoute />,
                  }
                },
              },
              {
                path: PATH.SITE_OVERVIEW,
                handle: {
                  meta: () => [{ title: pageTitle.siteOverview }],
                },
                async lazy() {
                  const mod = await import('./sites')

                  return {
                    element: <mod.SitesRoute />,
                  }
                },
              },
            ],
          },
        ],
      },
    ],
  },
]
