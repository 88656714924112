export const tagTypes = {
  all: 'All',
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  other: 'Other',
  scheduledLoss: 'ScheduledLoss',
  unassigned: 'Unassigned',
}

export const tags = {
  all: 'All',
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  other: 'Other',
  scheduledLoss: 'ScheduledLoss',
  unassigned: 'Unassigned',
}

export const tagsStr = {
  all: 'All',
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  other: 'Other',
  scheduledLoss: 'Scheduled Loss',
  unassigned: 'Unassigned',
}

export const tagItems = {
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  sl: 'SL',
  operator: 'Operator',
  ulf: 'ULF',
  ulo: 'ULO',
  other: 'Other',
  unassigned: 'Unassigned',
}